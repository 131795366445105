import Head from 'next/head';
import { FC } from 'react';

interface Props {
  pageName: string;
}

const APP_TITLE = 'CAFTA Directory';

export const NextHead: FC<Props> = ({ pageName }) => {
  const pageTitle = [pageName, APP_TITLE].join(' - ');

  return (
    <Head>
      <title>{pageTitle}</title>
    </Head>
  );
};
